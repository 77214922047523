import type { AreaFromGetAreas } from '@/types'
import type { User } from '@auth0/auth0-vue'
import type { MaybeRef } from 'vue'

export function useIframeMessages(_iframe: MaybeRef<HTMLIFrameElement | null>, targetDomain: MaybeRef<string> = '*') {
  const ready = ref(false)
  const unauthorized = ref(false)
  const refreshTokenRequested = ref(false)

  useEventListener(window, 'message', (event) => {
    switch (event.data) {
      case 'ready':
        ready.value = true
        break
      case 'unauthorized':
        unauthorized.value = true
        break
      case 'refreshTokenRequest':
        refreshTokenRequested.value = true
        break
      default:
        // console.log('Unknown message:', event.data)
    }
  })

  function sendMessage(message: string) {
    const iframe = unref(_iframe)
    if (iframe && ready.value)
      iframe.contentWindow?.postMessage(message, unref(targetDomain))
  }

  function sendSetAllAreasMessage(areas: MaybeRef<AreaFromGetAreas[] | AreaFromGetAreas | undefined>) {
    const data = unref(areas)
    if (!data)
      return
    sendMessage(JSON.stringify({ postMessageType: 'setAreas', data }))
  }

  function sendSetSelectedAreasMessage(selected: MaybeRef<AreaFromGetAreas[] | AreaFromGetAreas | undefined>) {
    const data = unref(selected)
    if (!data)
      return

    if (Array.isArray(data) && data.length > 0)
      sendMessage(JSON.stringify({ postMessageType: 'setSelectAreas', data }))
    else if (typeof data === 'object' && data)
      sendMessage(JSON.stringify({ postMessageType: 'setSelectArea', data }))
  }

  function sendSetLocaleMessage(loc: MaybeRef<string>) {
    const data = unref(loc)
    if (data)
      sendMessage(JSON.stringify({ postMessageType: 'setLocale', data }))
  }

  function sendAuth0TokensMessage(accessToken: MaybeRef<string | undefined>, user?: MaybeRef<User | undefined>) {
    const data = {
      accessToken: unref(accessToken),
      username: unref(user)?.name,
      email: unref(user)?.email,
      isEmbedded: true,
      authUserId: unref(user)?.sub,
    }
    if (data)
      sendMessage(JSON.stringify({ postMessageType: 'setTokens', data }))
  }

  return {
    ready,
    sendSetAllAreasMessage,
    sendSetSelectedAreasMessage,
    sendSetLocaleMessage,
    sendAuth0TokensMessage,
  }
}
