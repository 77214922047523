import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import messages from '@intlify/unplugin-vue-i18n/messages'
import { VueQueryPlugin } from '@tanstack/vue-query'
import mixpanel from 'mixpanel-browser'

import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import App from './App.vue'

import SidebarLayout from './layouts/SidebarLayout.vue'
import WizardLayout from './layouts/Wizard.vue'

import router from './router'
import { auth0 } from './utils/auth0'
import { datadogConfig, datadogRumConfig } from './utils/logger'

import 'uno.css'
import '@unocss/reset/tailwind-compat.css'

import '@/assets/styles.css'

if (import.meta.env.PROD) {
  datadogLogs.init(datadogConfig)
  datadogRum.init(datadogRumConfig)
  datadogRum.startSessionReplayRecording()
  mixpanel.init(import.meta.env.VITE_APP_MIXPANEL_TOKEN)
}

const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: !(!import.meta.env.DEV || import.meta.env.VITE_BUILD_STAGE === 'staging'), // only refetch on window focus in production
        retry: 3, // we retry each query a maximum of 3 times
        retryDelay: (attemptIndex: number) => Math.min(1000 * 2 ** attemptIndex, 5000), // double delay between each retry and never go above 5 seconds
      },
    },
  },
}

const i18n = createI18n({
  legacy: false,
  locale: navigator.language, // TODO: get from browser after we have other language as well
  fallbackLocale: 'en',
  messages,
})

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(auth0)
app.use(VueQueryPlugin, vueQueryPluginOptions)
app.use(i18n)

app.component('SidebarLayout', SidebarLayout)
app.component('WizardLayout', WizardLayout)

async function prepareMsw() {
  if (import.meta.env.MODE === 'mock') {
    const { worker } = await import('./mocks/browser')
    await worker.start({ onUnhandledRequest: 'warn' })
  }
  else {
    return Promise.resolve()
  }
}

prepareMsw().then(() => {
  app.mount('#app')
})
