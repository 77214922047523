<script lang="ts" setup>
import type { Group } from '@/types'
import { useGetAllAreas } from '@/composables/openapi/areaTree/useGetAllAreas'
import { useSearchTicketGroupsByArea } from '@/composables/openapi/group/useSearchTicketGroupsByArea'
import { useUploadFile } from '@/composables/openapi/user/useUploadFile'
import { useVerifyFile } from '@/composables/openapi/user/useVerifyFile'
import { bytesToReadable } from '@/types'
import { accountVerificationWizardSchema } from '@/types/schemas/accountVerificationWizard'
import { createLaunchDarklyClient, isBulkUpdateVerificationCodesEnabled } from '@/utils/launchDarkly'
import {
  logChangeStep,
  logFinishAccountVerification,
  logSelectArea,
  logSelectGroup,
  logUploadFile,
  logVerificationSubmitErrorDialogClose,
  logVerifyFile,
} from '@/utils/logger'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm, useFormErrors } from 'vee-validate'
import { toast } from 'vue-sonner'
import router from '../../router/index'

defineOptions({
  name: 'AccountVerificationWizard',
})

const { t } = useI18n()
// the schema for the form consist in one object for each step

const formValidationSchema = toTypedSchema(accountVerificationWizardSchema)

// we need to validate on mount so we can set the errors on first mount
// this is needed for the `useFormErrors` composable to initialize the errors object
const { handleSubmit, values, setFieldValue, validate } = useForm({
  validationSchema: formValidationSchema,
  initialValues: {
    stepOne: {
      areaId: '',
    },
    stepTwo: {
      groupIds: [],
    },
    stepThree: {
      inputFile: null,
    },
  },
  validateOnMount: true,
})

// this composable returns all the errors within the form.
// if there's no error for a field, the key will not be present in the object

// this is the state of the form
const steps = ref([
  {
    name: t('account_verification.steps.select_area'),
    content: '',
    current: true,
    valid: false,
  },
  {
    name: t('account_verification.steps.select_group'),
    content: '',
    current: false,
    valid: false,
  },
  {
    name: t('account_verification.steps.import_accounts'),
    content: '',
    current: false,
    valid: false,
  },
  {
    name: t('account_verification.steps.verification_and_confirmation'),
    content: '',
    current: false,
    valid: false,
  },
])

// this is the step index that is currently active
const currentStepIndex = ref(0)

const formErrors = useFormErrors()
const formErrorsKeys = computed(() => {
  return Object.keys(formErrors.value)
})

// step 1 data
const { areas, isLoading: isGetAreasLoading, isError: isGetAreasError } = useGetAllAreas(true)

const options = computed(() => {
  return areas.value.map(area => ({
    name: [area.address?.city, area.name].filter(s => s).join(', '),
    value: area.id,
  }))
})

const selectedArea = computed(() => {
  return values.stepOne?.areaId ?? ''
})

const areaName = computed(() => {
  return areas.value.find(area => area.id === selectedArea.value)?.name ?? ''
})

const displayBulkUploadSection = ref(false)

async function checkBulkUploadFlagStatus() {
  const ldClient = createLaunchDarklyClient(selectedArea.value)
  if (ldClient) {
    displayBulkUploadSection.value = await isBulkUpdateVerificationCodesEnabled(ldClient)
  }
  else {
    displayBulkUploadSection.value = false
  }
}

watch(isGetAreasError, () => {
  toast.error(t('error_fallback'))
  router.go(-1)
})

// step 2
const { groups, isLoading: isGroupsLoading, isError: isGroupError } = useSearchTicketGroupsByArea(selectedArea, computed(() => selectedArea.value !== ''))

watch(isGroupError, () => {
  if (isGroupError.value) {
    toast.error(t('account_verification.select_group_error'))
  }
})

// Step 3
const {
  isPending: isVerifyingFile,
  mutate: verifyFile,
  data: verifyFileData,
  errorResponse: verifyFileErrors,
  isError: isVerifyFileError,
} = useVerifyFile()

// step 5 data
const {
  isPending: isUploadingFile,
  mutate: uploadFile,
  isError: isUploadError,
} = useUploadFile()

const submitDialogOpen = ref(false)

function closeDialog() {
  logFinishAccountVerification()
  submitDialogOpen.value = false
  router.push({ name: 'account-verification' })
}

watch(currentStepIndex, () => {
  validate()
  steps.value[3].valid = currentStepIndex.value > 2
})

watch(selectedArea, (value) => {
  if (value) {
    logSelectArea(areaName.value)
  }
  setFieldValue('stepTwo.groupIds', [])
})

watch(isVerifyFileError, () => {
  if (isVerifyFileError.value) {
    setFieldValue('stepThree.inputFile', null)
  }
})

// watch input file for reupload
const inputFile = computed(() => values.stepThree?.inputFile)
watch(inputFile, () => {
  validate()
  if (inputFile.value && currentStepIndex.value === 2) {
    handleVerifyFile()
  }
})

const previousStepIndex = computed(() => {
  // get the index of the step before the current one
  const index = currentStepIndex.value - 1
  // if the index is less than 0, there's no previous step.
  return index
})
const nextStepIndex = computed(() => {
  // get the index of the step after the current one
  const index = currentStepIndex.value + 1
  // if the index is greater than the length of the steps array, return null, else return the index
  return index < steps.value.length ? index : undefined
})

const groupName = computed(() => {
  const selectedGroups = values.stepTwo?.groupIds
  if (selectedGroups && selectedGroups.length > 0) {
    const firstGroupName = groups.value.find(
      (g: Group) => g.id === selectedGroups[0],
    )?.label
    const subtext
      = selectedGroups.length === 1
        ? ''
        : ` (+ ${selectedGroups.length - 1} ${t('others')})`
    return `${firstGroupName}${subtext}`
  }
  return ''
})

watchDeep([formErrorsKeys, values], () => {
  steps.value[0].valid = !formErrorsKeys.value.find(key =>
    key.startsWith('stepOne'),
  )
  steps.value[0].content = areaName.value ?? ''

  steps.value[1].valid = !formErrorsKeys.value.find(key =>
    key.startsWith('stepTwo'),
  )
  steps.value[1].content = groupName.value

  steps.value[2].valid = !formErrorsKeys.value.find(key =>
    key.startsWith('stepThree'),
  )
  steps.value[2].content = values.stepThree?.inputFile?.name ?? ''
})

// this is the object of the next step, containing all the properties
const nextStepObject = computed(() => {
  if (nextStepIndex.value === undefined) {
    return undefined
  }
  return steps.value[nextStepIndex.value]
})

const uploadedAccountsNumber = ref(0)
// this function is called when the user clicks on the next or previous button
function setCurrentStep(index: number) {
  logChangeStep(steps.value[index].name, index)
  // set the current step to false
  steps.value[currentStepIndex.value].current = false
  currentStepIndex.value = index
  // set the new current step to true
  steps.value[index].current = true

  if (currentStepIndex.value === 2) {
    handleVerifyFile()
  }
}

watch(verifyFileData, (value) => {
  uploadedAccountsNumber.value = value?.uploadedCodes ?? 0
})

// this is the computed property that checks if the current step is valid
const currentStepValid = computed(() => {
  if (currentStepIndex.value === 2)
    return steps.value[currentStepIndex.value].valid && !verifyFileErrors.value
  return steps.value[currentStepIndex.value].valid
})

const loading = computed(() => {
  return isGroupsLoading.value || isVerifyingFile.value || isUploadingFile.value
})

const hideWizardStepsComponent = computed(() => currentStepIndex.value >= 3)

const joinedGroupsName = computed(() => {
  return groups.value
    .filter((group: Group) => values.stepTwo?.groupIds?.includes(group.id))
    .map((group: Group) => group.label)
    .join(', ')
})

watch(joinedGroupsName, (value) => {
  logSelectGroup(value)
})

const fileSize = computed(() => {
  return bytesToReadable(values.stepThree?.inputFile?.size ?? 0)
})

const fileName = computed(() => {
  return values.stepThree?.inputFile?.name ?? ''
})

const dialogHeadline = computed(() => {
  return isUploadingFile.value
    ? t('account_verification.dialog.loading.headline')
    : isUploadError.value
      ? t('account_verification.dialog.error.headline')
      : t('account_verification.dialog.success.headline')
})

function handleVerifyFile() {
  // if the file is valid, set the step to valid
  if (
    values.stepThree?.inputFile === undefined
    || values.stepThree?.inputFile === null
  ) {
    return
  }
  const requestPayload = {
    areaEntityId: values.stepOne?.areaId ?? '',
    groupIds: values.stepTwo?.groupIds?.join(',') ?? '',
    file: values.stepThree.inputFile as File,
  }
  logVerifyFile(
    values.stepOne?.areaId || '',
    values.stepTwo?.groupIds || [],
    fileName.value,
    fileSize.value,
  )
  verifyFile(requestPayload)
}

const submitForm = handleSubmit(() => {
  logUploadFile(
    values.stepOne?.areaId || '',
    values.stepTwo?.groupIds || [],
    fileName.value,
    fileSize.value,
  )
  uploadFile({
    areaEntityId: values.stepOne?.areaId ?? '',
    groupIds: values.stepTwo?.groupIds?.join(',') ?? '',
    file: values.stepThree?.inputFile as File,
  })
  submitDialogOpen.value = true
})

function handleUploadError() {
  logVerificationSubmitErrorDialogClose()
  submitDialogOpen.value = false
}

watch(selectedArea, async () => {
  await checkBulkUploadFlagStatus()
})
</script>

<template>
  <div
    class="flex flex-col md:mx-auto md:mt-15 md:w-3xl md:flex-row md:flex-row-reverse md:px-4 lg:w-5xl"
  >
    <WizardSteps
      v-if="!hideWizardStepsComponent"
      :steps="steps"
      :current-step-index="currentStepIndex"
      desktop-width="w-2/5"
    />
    <form
      class="mb-20 w-full p-4 md:p-0"
      :class="{ 'md:w-3/5 md:mr-10': !hideWizardStepsComponent }"
    >
      <WizardSection v-show="currentStepIndex === 0" :title="steps[0].name">
        <FormSection :title="t('search')">
          <FormDropdown
            name="stepOne.areaId"
            :options="options"
            :loading="isGetAreasLoading"
            selected-icon="i-custom:area"
          />
        </FormSection>
      </WizardSection>
      <WizardSection v-show="currentStepIndex === 1" :title="steps[1].name">
        <FormSection :title="t('account_verification.steps.select_group')">
          <FormPriceGroup
            name="stepTwo.groupIds"
            :options="groups"
            :loading="isGroupsLoading"
          />
        </FormSection>
      </WizardSection>
      <WizardSection v-show="currentStepIndex === 2" :title="steps[2].name">
        <AccountVerificationReview
          :loading="isVerifyingFile"
          :error-response="verifyFileErrors"
          :has-been-uploaded="values.stepThree?.inputFile !== null"
        >
          <FormInputFile name="stepThree.inputFile" :data-types="['.csv', '.xlsx']" />
        </AccountVerificationReview>
        <!-- TODO Display the option to bulk upload based on flag status -->
        <div v-if="displayBulkUploadSection">
          Bulk Upload Enabled
        </div>
      </WizardSection>
      <WizardSection v-show="currentStepIndex === 3" :title="steps[3].name">
        <FormSection :title="t('accounts')">
          <AccountVerificationConfirm
            :uploaded-accounts-number="uploadedAccountsNumber"
            :file-name="fileName"
            :file-size="fileSize"
            :group="joinedGroupsName"
            :area="areaName"
          />
        </FormSection>
      </WizardSection>
    </form>
  </div>
  <!-- success or error dialog box -->
  <Dialog :open="submitDialogOpen" :loading="isUploadingFile">
    <template #icon>
      <div v-if="isUploadError" class="i-custom:error-badge h-14 w-14" />
      <div v-else class="i-custom:verified-badge h-14 w-14" />
    </template>
    <template #headline>
      {{ dialogHeadline }}
    </template>
    <template v-if="isUploadingFile" #content>
      {{ t('account_verification.dialog.loading.content') }}
    </template>
    <template v-if="!isUploadingFile" #buttons>
      <BaseButton
        v-if="isUploadError"
        class="w-full"
        @click="handleUploadError"
      >
        {{ t("reupload") }}
      </BaseButton>
      <BaseButton v-else class="w-full" @click="closeDialog">
        {{ t("continue") }}
      </BaseButton>
    </template>
  </Dialog>
  <WizardNav :next-step-name="nextStepObject?.name" :next-step-index="nextStepIndex" :next-step-disabled="!currentStepValid || loading" :previous-step-index="previousStepIndex" :loading="loading" @navigate-to-step="setCurrentStep" @submit-form="submitForm" />
</template>
