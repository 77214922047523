<script lang="ts" setup>
import type { AreaFromGetAreas } from '@/types'
import { useAreaSelector } from '../useAreaSelector'
import { getAreaLabel } from '../utils'

defineOptions({
  name: 'AppHeaderAreaSelectorVisualizer',
})

const props = defineProps<{
  /**
   * The areas to display in the selector
   */
  areas: AreaFromGetAreas[]
  /**
   * Whether to allow selecting multiple areas; will switch between a select and multi-select component
   * @default true (multi-select)
   */
  multiple?: boolean

}>()
const open = defineModel('open', {
  default: false,
})

const { t } = useI18n()

const { Ctrl_K, MetaLeft_K, MetaRight_K } = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if ((e.ctrlKey || e.metaKey) && e.key === 'k' && e.type === 'keydown') {
      e.preventDefault()
    }
  },
})
whenever(Ctrl_K, () => open.value = !open.value)
whenever(MetaLeft_K, () => open.value = !open.value)
whenever(MetaRight_K, () => open.value = !open.value)

const { areas, multiple } = toRefs(props)
const {
  handleSelectAll,
  selectedAreasArray,
  unselectArea,
} = useAreaSelector(areas, multiple, true)

const selectedAreasAmount = computed(() => selectedAreasArray.value.length)

const isToggleHovered = ref(false)

// get the first 4 selected areas to display in the visualizer
// if there are less than 4, display all
const firstSelectedAreas = computed(() => {
  return selectedAreasArray.value.slice(0, 4)
})

const route = useRoute()

const serviceNote = computed(() => {
  if (selectedAreasArray.value.length === 1 && route.name === 'service') {
    return selectedAreasArray.value[0]?.note?.service
  }
  return undefined
})

function handleDeselectAll() {
  open.value = true
  handleSelectAll(false)
}

function handleUnselectTheLastSelectedArea(id: string) {
  if (selectedAreasArray.value.length === 1) {
    open.value = true
  }
  unselectArea(id)
}
</script>

<template>
  <div>
    <div class="relative h-10 flex">
      <AppHeaderAreaSelectorVisualizerInfoDialog v-if="serviceNote" :note="serviceNote" />
      <div
        class="flex cursor-pointer items-center border-1 border-white/40 rounded-lg bg-white/20 py-1 pl-3 pr-1.5 text-white shadow transition-opacity"
        :class="[{
          'opacity-60': isToggleHovered,
          'opacity-100': !isToggleHovered,
        }]" @click="open = true"
      >
        <button class="i-lucide:search h-5 w-5 text-white leading-none" />
        <div class="relative ml-2.5 w-106 flex items-center gap-2 overflow-hidden">
          <p v-if="selectedAreasArray.length === 0" class="leading-none">
            {{ t('search') }}
          </p>
          <template v-else>
            <AppHeaderAreaSelectorVisualizerItem
              v-for="area in firstSelectedAreas" :id="area.id"
              :key="`visualizer-item-${area.id}`" :multiple="props.multiple" :label="getAreaLabel(area)"
              @remove="handleUnselectTheLastSelectedArea"
            />
          </template>
        </div>
        <div
          v-if="selectedAreasArray.length > 4"
          class="absolute right-2 h-9 w-20 flex items-center justify-right rounded from-ppGreenMedium via-ppGreenMedium to-ppGreenMedium/0 bg-gradient-to-l"
        >
          <div class="h-8 w-8 flex items-center justify-center rounded bg-white text-ppGreenLight">
            <p class="text-3 font-600">
              {{ `+${selectedAreasArray.length - 4}` }}
            </p>
          </div>
        </div>
      </div>

      <AppHeaderAreaSelectorVisualizerToggleSelectAll
        v-if="multiple" class="absolute -right-11"
        :selected-amount="selectedAreasAmount" @hover="e => isToggleHovered = e"
        @deselect-all="handleDeselectAll()" @select-all="handleSelectAll(true)"
      />
    </div>
    <AppHeaderAreaSelectorModal v-model:open="open" :multiple="props.multiple" :areas="areas" />
  </div>
</template>
