import type { ComputedRef, MaybeRef } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { useOpenApiClient } from './useUserOpenApiClient'

export function useGetVerificationSummary(isEnabled: MaybeRef<boolean> | ComputedRef<boolean> = true) {
  const { apiInstance } = useOpenApiClient()

  const {
    isPending,
    isError,
    isSuccess,
    data,
    error,
    refetch,
    suspense,
  } = useQuery({
    queryFn: () => apiInstance.value.verificationCodeControllerGetSummary(),
    queryKey: ['verificationSummary'],
    networkMode: 'always',
    enabled: isEnabled,
  })
  return {
    isPending,
    isError,
    isSuccess,
    data,
    error,
    suspense,
    refetch,
  }
}
