import type { DefaultApiVerificationCodeControllerFindByAreaEntityIdRequest } from '@peter-park/user'
import type { ComputedRef, MaybeRef } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { useOpenApiClient } from './useUserOpenApiClient'

export function useGetVerificationCodesByArea(payload: MaybeRef<DefaultApiVerificationCodeControllerFindByAreaEntityIdRequest>, isEnabled: MaybeRef<boolean> | ComputedRef<boolean> = true) {
  const { apiInstance } = useOpenApiClient()

  const {
    isPending,
    isError,
    isSuccess,
    data,
    error,
    refetch,
    suspense,
    isFetching,
  } = useQuery({
    queryFn: () => apiInstance.value.verificationCodeControllerFindByAreaEntityId(unref(payload)),
    queryKey: ['verificationCodesByArea', unref(payload).areaEntityId],
    networkMode: 'always',
    enabled: isEnabled,
  })
  return {
    isPending,
    isError,
    isSuccess,
    data,
    error,
    isFetching,
    suspense,
    refetch,
  }
}
