import type { Permission } from '@/types'
import { jwtDecode } from 'jwt-decode'

export function getPermissions(accessToken: string): Set<Permission> {
  const decodedAccessToken = jwtDecode(accessToken) as Record<string, string | string[] | number>
  const permissions = decodedAccessToken.permissions as Permission[]
  const userPermissions = new Set(permissions)
  return userPermissions
}

export function checkPermissions(desiredPermissions: Permission[], currentPermissions: Set<Permission>, every = false): boolean {
  // Generic method which accepts a set of permissions as ['create:something', 'view:something']; compares it with the user's permissions and returns a boolean
  if (every) {
    return desiredPermissions.every(permission => currentPermissions.has(permission))
  }
  return desiredPermissions.some(permission => currentPermissions.has(permission))
}
