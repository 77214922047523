<script setup lang="ts">
import { useGetAreas } from '@/composables/useGetAreas'
import { toast } from 'vue-sonner'

defineOptions({
  name: 'AppHeader',
})

const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const multipleAreaSelector = computed(() => {
  return route.meta.multiArea ?? false
})

const isLightstealthPage = computed<boolean>(() => {
  return route.meta.lightstealth ?? false
})

const { areas, isLoading, isError } = useGetAreas(isLightstealthPage)
watch(isError, () => {
  if (isError) {
    toast.error(t('error_fallback'))
    router.push({ name: 'home' })
  }
})

const open = ref(false)
</script>

<template>
  <header
    class="fixed z-10 h-18 w-full flex items-center justify-between border-b-1 bg-pp-green px-3 md:h-28 md:justify-end"
    data-testid="main-header-wrapper"
  >
    <div class="flex shrink-0 items-center justify-between md:hidden">
      <router-link to="/">
        <img class="h-8" src="@/assets/logo.svg" alt="Peter Park">
      </router-link>
    </div>
    <AppHeaderHamburgerMenu />
    <Teleport v-if="isLightstealthPage" to="body">
      <div class="absolute right-14 top-5 z-30 h-10 w-100 rounded bg-white/20 transition-opacity hidden md:hidden" :class="isLoading ? 'animate-pulse opacity-100' : 'opacity-0'" />
      <div class="absolute top-5 z-30 transition hidden md:block" :class="[isLoading ? 'opacity-0' : 'opacity-100', multipleAreaSelector ? 'right-16' : 'right-5']">
        <AppHeaderAreaSelectorVisualizer v-model:open="open" :multiple="multipleAreaSelector" :areas="areas" />
      </div>
    </Teleport>
  </header>
</template>
