<script lang="ts" setup>
defineOptions({
  name: 'AppHeaderAreaSelectorVisualizerToggleSelectAll',
})

const props = defineProps<{
  /**
   * The number of selected areas, change icon based on the number of selected areas
   */
  selectedAmount: number
}>()
const emit = defineEmits<{
  /**
   * Emit when the user hover the button
   */
  hover: [value: boolean]
  /**
   * Emit when the user clicks on select all
   */
  selectAll: []
  /**
   * Emit when the user clicks on deselect all
   */
  deselectAll: []
}>()
const { t } = useI18n()

const showSelectAll = computed(() => {
  return props.selectedAmount <= 1
})

const toggleIcon = computed(() => {
  return showSelectAll.value ? 'i-custom:select-all' : 'i-custom:paragraph-spacing'
})
const toggleText = computed(() => {
  return showSelectAll.value ? t('select_all') : t('deselect_all')
})
const toggleButtonElement = ref()
const isHovered = useElementHover(toggleButtonElement)
watch(isHovered, (value) => {
  emit('hover', value)
})

function handleClick() {
  if (showSelectAll.value) {
    emit('selectAll')
  }
  else {
    emit('deselectAll')
  }
}
</script>

<template>
  <button
    ref="toggleButtonElement"
    class="h-full overflow-hidden border-1 rounded-lg px-2 py-2 text-white leading-none shadow transition-all"
    :class="[
      { 'border-white/40 bg-white/20': !isHovered },
      { 'border-white bg-ppGreenMediumDark': isHovered },
    ]"
    @click="handleClick()"
  >
    <div class="flex items-center rounded-lg bg-transparent leading-none">
      <div class="h-5 w-5" :class="toggleIcon" />
      <p
        class="text-xs font-600 leading-none transition-all"
        :class="[
          { 'max-w-0 h-0 opacity-0 pointer-events-none': !isHovered },
          { 'max-w-100 gap-1.5 h-full opacity-100 ml-1.5': isHovered },
        ]"
      >
        {{ toggleText }}
      </p>
    </div>
  </button>
</template>
