import type { ComputedRef, MaybeRef } from 'vue'
import { getAreas } from '@/utils'

import { experimental_createPersister } from '@tanstack/query-persist-client-core'
import { useQuery } from '@tanstack/vue-query'

export function useGetAreas(isEnabled: MaybeRef<boolean> | ComputedRef<boolean> = true) {
  const {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
    error,
    refetch,
    suspense,
  } = useQuery({
    queryKey: ['getAreas'],
    queryFn: () => getAreas(),
    enabled: isEnabled,
    persister: experimental_createPersister({
      storage: localStorage,
    }),
    // refresh the data every 5 minutes
    staleTime: 1000 * 60 * 5,
  })

  const areas = computed(() => {
    return data.value?.areas ?? []
  })

  const areasNameAndUUID = computed(() => {
    return areas.value.map((area) => {
      return {
        value: area.uuid,
        name: `${area.locality}, ${area.name}`,
      }
    })
  })
  const areasUUID = computed(() => {
    return areas.value.map(area => area.uuid)
  })
  return {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
    error,
    refetch,
    suspense,
    areas,
    areasNameAndUUID,
    areasUUID,
    suspenseGetAreas: suspense,
  }
}
