import * as LDClient from 'launchdarkly-js-client-sdk'

type MaybeLDClient = LDClient.LDClient | undefined

export function createLaunchDarklyClient(areaId: string): MaybeLDClient {
  try {
    const launchDarklyClientID = import.meta.env.VITE_B2B_DASHBOARD_LAUNCHDARKLY_CLIENT_ID
    const launchDarklyContext = {
      kind: 'user',
      key: `area-${areaId}`,
      custom: { app: 'b2b-dashboard' },
    }
    return LDClient.initialize(launchDarklyClientID, launchDarklyContext)
  }
  catch (error) {
    if (error instanceof Error)
      console.error(error.message)
    console.error(`createLaunchDarklyClient error: ${error}`)
  }
}

export enum LaunchDarklyFlags {
  EnableBulkUpdateVerificationCodes = 'enable-bulk-update-verification-codes',
}

export async function isBulkUpdateVerificationCodesEnabled(client: MaybeLDClient): Promise<boolean> {
  if (!client)
    return false

  try {
    await client.waitForInitialization()
    const flagValue: boolean = client.variation(
      LaunchDarklyFlags.EnableBulkUpdateVerificationCodes,
    )
    return flagValue
  }
  catch (error) {
    if (error instanceof Error)
      console.error(error.message)
    console.error(`isBulkUpdateVerificationCodesEnabled error: ${error}`)
    return false
  }
}
