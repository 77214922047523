<script lang="ts" setup>
defineOptions({
  name: 'AccountVerificationView',
})
const { t } = useI18n()
</script>

<template>
  <div class="mx-4 my-8 flex flex-col gap-6 lg:mx-8 lg:gap-8">
    <div class="lg:b-b-1 lg:pb-6">
      <h4 class="text-3xl color-gray-900 font-bold leading-9">
        {{ t('account_verification.title') }}
      </h4>
      <h4 class="text-base color-gray-600 leading-6">
        {{ t('account_verification.subtitle') }}
      </h4>
    </div>
    <AccountVerificationContentTemplateAndGuidelines />
    <Suspense>
      <AccountVerificationContentAreaCards />
      <template #fallback>
        <AccountVerificationContentAreaCardsLoading />
      </template>
    </Suspense>
  </div>
</template>
