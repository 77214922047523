import type { ResponseError } from '@/types'
import type { DefaultApiVerificationCodeControllerValidateVerificationCodesRequest, VerificationsGenericErrorDto } from '@peter-park/user'
import { useMutation } from '@tanstack/vue-query'
import { useOpenApiClient } from './useUserOpenApiClient'

export function useVerifyFile() {
  const { apiInstance } = useOpenApiClient()

  const errorResponse = ref<VerificationsGenericErrorDto>()

  const {
    isPending,
    isError,
    isSuccess,
    data,
    error,
    mutate,
  } = useMutation({
    mutationFn: (payload: DefaultApiVerificationCodeControllerValidateVerificationCodesRequest) => apiInstance.value.verificationCodeControllerValidateVerificationCodes(payload), // TODO check if this also needs to be updated after BE changes
    mutationKey: ['verifyFile'],
    networkMode: 'always',
    // Workaround to get back the response in case of error
    onError: async (error: ResponseError) => {
      errorResponse.value = await error.response.json() as VerificationsGenericErrorDto
    },
    onSuccess: () => {
      errorResponse.value = undefined
    },
  })
  return {
    isPending,
    isError,
    isSuccess,
    data,
    error,
    errorResponse,
    mutate,
  }
}
