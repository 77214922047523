<script lang="ts" setup>
import type { VerificationCodeDetailsDto } from '@peter-park/user'
import { useGetVerificationCodesByArea } from '@/composables/openapi/user/paginated/useGetVerificationCodesByArea'
import { useDeleteVerificationCodes } from '@/composables/openapi/user/useDeleteVerificationCodes'
import { useRevertVerificationCodes } from '@/composables/openapi/user/useRevertVerificationCodes'
import { useDateFns } from '@peter-park/vue-common-utils'
import { createColumnHelper } from '@tanstack/vue-table'
import { toast } from 'vue-sonner'

defineOptions({
  name: 'AccountVerificationSummaryTable',
})

const props = defineProps<{
  totalCount?: number
  totalCountLoading?: boolean
}>()

const { t } = useI18n()
const route = useRoute()

const badges = computed(() => {
  return [
    {
      label: props.totalCount ? t('account_verification.summary.total', { total: props.totalCount }) : '',
      loading: props.totalCountLoading,
    },
  ]
})

const areaEntityId = Array.isArray(route.params.areaId) ? route.params.areaId[0] : route.params.areaId

const {
  error: getCodesError,
  hasNextPage,
  hasPreviousPage,
  isFetching,
  loadNextPage,
  resetCodes,
  searchQuery,
  verificationCodes,
} = useGetVerificationCodesByArea(areaEntityId)

watch(getCodesError, (error) => {
  if (error) {
    toast.error(t('error_fallback'))
  }
})

const tablePageSize = ref(10)
const showMultiSelectActions = ref(false)
const columnHelper = createColumnHelper<VerificationCodeDetailsDto>()

const { format } = useDateFns()
const columns = computed(() => {
  return [
    columnHelper.accessor(row => row.description, {
      id: 'description',
      cell: info => info.getValue() ?? 'N/A',
      header: () => t('description'),
    }),
    columnHelper.accessor(row => row.code, {
      id: 'code',
      cell: info => info.getValue() ?? 'N/A',
      header: () => t('verification_code'),
    }),
    columnHelper.accessor(row => row.updatedAt, {
      id: 'updatedAt',
      cell: info => info.getValue() ? format(info.getValue(), 'PP, p').value : 'N/A',
      header: () => t('updated_at'),
    }),
    columnHelper.accessor(row => row.isVerified, {
      id: 'isVerified',
      cell: info => info.getValue() ? t('yes') : t('no'),
      header: () => t('verified'),
    }),
  ]
})

const selectedRowIndexes = ref<number[]>([])
const selectedCodes = computed(() => {
  return selectedRowIndexes.value?.map(index => verificationCodes.value[index]) ?? []
})
const selectedRevertableCodes = computed(() => {
  return selectedCodes.value.filter(code => code?.isVerified)
})

function dialogConfirmationFactory(
  codes: ComputedRef<VerificationCodeDetailsDto[]>,
  mutation: typeof useDeleteVerificationCodes | typeof useRevertVerificationCodes,
  successMessage: string,
  errorMessage: string,
) {
  // open/close logic + table click handler
  const isOpen = ref(false)

  function tableClick(index: number) {
    selectedRowIndexes.value = [index]
    isOpen.value = true
  }

  function close(reset = false) {
    isOpen.value = false
    if (reset)
      resetCodes()
  }

  // api call + feedbacks
  const { isSuccess, isPending, isError, mutate: _mutate } = mutation(areaEntityId)

  function mutate() {
    _mutate({ verificationCodeIds: codes.value.map(code => code.id) })
  }

  function loc(message: string) {
    const amount = codes.value.length
    return t(message, { amount, description: codes.value?.[0].description }, amount)
  }

  watch(isSuccess, () => {
    if (isSuccess.value) {
      toast.success(loc(successMessage))
      close(true)
    }
  })

  watch(isError, () => {
    if (isError.value)
      toast.error(loc(errorMessage))
  })

  return {
    close,
    isOpen,
    isPending,
    mutate,
    tableClick,
  }
}

const {
  close: closeRevertDialog,
  isOpen: revertDialog,
  isPending: isRevertLoading,
  mutate: revertCodes,
  tableClick: onTableRevertClick,
} = dialogConfirmationFactory(
  selectedRevertableCodes,
  useRevertVerificationCodes,
  'account_verification.revert.success',
  'account_verification.revert.error',
)

const {
  close: closeDeleteDialog,
  isOpen: deleteDialog,
  isPending: isDeleteLoading,
  mutate: deleteCodes,
  tableClick: onTableDeleteClick,
} = dialogConfirmationFactory(
  selectedCodes,
  useDeleteVerificationCodes,
  'account_verification.delete.success',
  'account_verification.delete.error',
)

function handleTableRowSelection() {
  showMultiSelectActions.value = selectedRowIndexes.value.length > 0
}

const multiselectRevertButtonEnabled = computed(() => selectedRevertableCodes.value.length > 0)
</script>

<template>
  <BaseTable
    id="verification-codes-table"
    v-model:selected-row-indexes="selectedRowIndexes"
    :title="t('account_verification.summary.accounts')"
    :data="verificationCodes"
    :columns="columns"
    :loading="isFetching"
    :sortable="false"
    :badges="badges"
    multi-select
    :cursor-pagination="{
      enabled: true,
      hasNextPage,
      hasPreviousPage,
    }"
    :page-size="tablePageSize"
    :total-count="verificationCodes.length"
    @on-filter-change="searchQuery = $event"
    @load-next="loadNextPage"
    @table-row-selection="handleTableRowSelection"
  >
    <template #additionalHeaderActions>
      <transition
        enter-active-class="transition duration-200 ease-out" enter-from-class="transform opacity-0"
        enter-to-class="transform opacity-100" leave-active-class="transition duration-200 ease-out"
        leave-from-class="transform opacity-100" leave-to-class="transform opacity-0"
      >
        <div v-if="showMultiSelectActions" class="w-fit flex items-center gap-2.5 rounded-2 bg-white p-1 pl-3 shadow-sm">
          <div class="flex items-center gap-2.5">
            <div class="my-auto h-4.5 content-center rounded-1 bg-ppGreenDeep px-1 text-xs text-white">
              {{ selectedCodes.length }}
            </div>
            <p class="text-xs text-gray-primary lowercase hidden sm:block">
              {{ t('selected') }}
            </p>
          </div>
          <div class="max-h-8 max-w-0 overflow-hidden transition-all" :class="{ 'max-w-40': multiselectRevertButtonEnabled }">
            <BaseButton
              :disabled="!multiselectRevertButtonEnabled"
              color="outline-gray"
              size="sm"
              class="transition-all"
              @click="revertDialog = true"
            >
              <div class="flex items-center gap-2 px-1 text-gray-700 font-600">
                <div class="i-custom:revert min-h-5 min-w-5" />
                {{ t('revert') }}
              </div>
            </BaseButton>
          </div>
          <BaseButton color="outline-gray" size="sm" @click="deleteDialog = true">
            <div class="flex items-center gap-2 px-1 text-gray-700 font-600">
              <div class="i-custom:delete min-h-5 min-w-5" />
              {{ t('remove') }}
            </div>
          </BaseButton>
        </div>
      </transition>
    </template>
    <template #actions="{ row }">
      <div class="flex gap-3 pl-3">
        <BaseTableActionButton
          :hint-text="t('account_verification.delete_code')"
          :disabled="showMultiSelectActions"
          @click="onTableDeleteClick(row.index)"
        >
          <div class="i-custom:delete min-h-5 min-w-5" />
        </BaseTableActionButton>
        <BaseTableActionButton
          v-if="row.original.isVerified"
          :disabled="showMultiSelectActions"
          :hint-text="t('account_verification.revert_code')"
          @click="onTableRevertClick(row.index)"
        >
          <div class="i-custom:revert min-h-5 min-w-5" />
        </BaseTableActionButton>
      </div>
    </template>
  </BaseTable>

  <ConfirmDialog
    :open="revertDialog"
    :loading="isRevertLoading"
    :headline="t('revert', selectedRevertableCodes.length)"
    :cancel-text="t('cancel')"
    :confirm-text="t('revert')"
    @click-confirm="revertCodes"
    @click-cancel="closeRevertDialog()"
  >
    <p>{{ t('account_verification.revert.content', { amount: selectedRevertableCodes.length }, selectedRevertableCodes.length) }}</p>
    <p class="pt-4">
      {{ t('account_verification.revert.multi_area_warning', { amount: selectedRevertableCodes.length }, selectedRevertableCodes.length) }}
    </p>
    <p v-if="selectedRevertableCodes.length === 1" class="pt-4 font-500">
      {{ `${selectedRevertableCodes?.[0].description}: ${selectedRevertableCodes?.[0].code}` }}
    </p>
  </ConfirmDialog>

  <ConfirmDialog
    :open="deleteDialog"
    :loading="isDeleteLoading"
    :headline="t('delete', selectedCodes.length)"
    :cancel-text="t('cancel')"
    :confirm-text="t('delete')"
    @click-confirm="deleteCodes"
    @click-cancel="closeDeleteDialog()"
  >
    <p>{{ t('account_verification.delete.content', { amount: selectedCodes.length }, selectedCodes.length) }}</p>
    <p v-if="selectedCodes.length === 1" class="pt-4 font-500">
      {{ `${selectedCodes?.[0].description}: ${selectedCodes?.[0].code}` }}
    </p>
  </ConfirmDialog>
</template>
