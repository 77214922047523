import type { AreaFromGetAreas } from '@/types'
import type { MaybeRef } from 'vue'
import { useFuse } from '@vueuse/integrations/useFuse'

/**
 * Composable to handle area selection; mainly used in the AreaSelector component
 * @param multiple boolean to determine if multiple areas can be selected or if only one area can be selected
 * @param areas an array of areas that can be selected
 * @param selectedAreas an array of areas (or a single area) that are selected
 * @param setSelectedAreas a function to set the selected areas
 */
export function useAreaSelection(
  multiple: MaybeRef<boolean> = true,
  areas: MaybeRef<AreaFromGetAreas[]> = [],
  selectedAreas: MaybeRef<AreaFromGetAreas[] | AreaFromGetAreas | undefined> = [],
  setSelectedAreas: (areas: AreaFromGetAreas[] | AreaFromGetAreas | undefined) => void = () => { },
) {
  /**
   * The search term used to filter the areas
   */
  const searchTerm = ref<string>('')
  const { results } = useFuse(
    searchTerm,
    areas,
    {
      fuseOptions: {
        keys: ['name', 'locality'],
        threshold: 0.3,
      },
      matchAllWhenSearchEmpty: true,
    },
  )

  /**
   * The filtered areas based on `searchTerm`.
   * If `searchTerm` is empty, all areas are returned
   */
  const filteredAreas = computed(() => {
    return results.value.map(result => result.item)
  })

  /**
   * the selected area as an object; if `multiple` is true, it is the first selected area, otherwise it is the selected area.
   * If no area is selected, it is undefined
   */
  const selectedAreaObject = computed(() => {
    const _selectedAreas = unref(selectedAreas)
    return Array.isArray(_selectedAreas) ? _selectedAreas[0] : _selectedAreas
  })

  /**
   * the selected areas as an array; if `multiple` is false, it is an array with the selected area, otherwise it is the selected areas.
   * If no area is selected, it is an empty array
   */
  const selectedAreasArray = computed(() => {
    const _selectedAreas = unref(selectedAreas)
    if (_selectedAreas)
      return Array.isArray(_selectedAreas) ? _selectedAreas : [_selectedAreas]

    return []
  })

  const selectedAreasIds = computed(() => {
    return new Set(unref(selectedAreasArray).map(a => a.id))
  })

  function isAreaSelected(area: AreaFromGetAreas) {
    return unref(selectedAreasIds).has(area.id)
  }

  /**
   * the unselected areas that are currently visible in the list
   */
  const unselectedFilteredAreasArray = computed(() => {
    return unref(filteredAreas).filter(a => !unref(selectedAreasIds).has(a.id))
  })

  /**
   * the selected areas that are currently visible in the list
   */
  const selectedFilteredAreasArray = computed(() => {
    const areas = unref(filteredAreas)
    const selectedIds = unref(selectedAreasIds)
    return areas.filter(a => selectedIds.has(a.id))
  })

  const areAllAreaSelected = computed(() => {
    return unref(selectedAreasArray).length === unref(areas).length
  })

  const isAtLeastOneAreaSelected = computed(() => {
    return unref(selectedAreasArray).length > 0
  })
  /**
   * Set the initial selected area(s) from `preSelectedAreas`.
   * If `multiple` is true, the `selectedAreas` will be an array, otherwise will be an object.
   */
  function setInitialSelectedAreaFromPreselection() {
    if (unref(multiple))
      setSelectedAreas(selectedAreasArray.value)
    else
      setSelectedAreas(selectedAreaObject.value)
  }

  /**
   * Set the selected area(s) from `areas`; it will use the first available area as the value.
   * If `multiple` is true, the `selectedAreas` will be an array, otherwise will be an object.
   */
  function setSelectedAreasFromAreas(setEmpty?: boolean) {
    if (setEmpty) {
      setSelectedAreas(unref(multiple) ? [] : undefined)
      return
    }
    if (unref(areas).length > 0) {
      const firstArea = unref(areas)[0]
      setSelectedAreas(unref(multiple) ? [firstArea] : firstArea)
    }
  }

  /**
   * Initialize the selected areas; if `preSelectedAreas` is defined, it will use that value via `setInitialSelectedAreaFromPreselection`, otherwise it will use `setSelectedAreasFromAreas`
   */
  function initializeSelectedAreas() {
    const sA = unref(selectedAreas)
    if (sA === undefined || (Array.isArray(sA) && sA.length === 0)) {
      setSelectedAreasFromAreas()
    }
    else {
      setInitialSelectedAreaFromPreselection()
    }
  }

  /**
   * if `selectedAreas` is an array, it will remove the area with the given `id`
   */
  function unselectArea(id: string) {
    const _selectedAreas = unref(selectedAreas)
    if (Array.isArray(_selectedAreas))
      setSelectedAreas(_selectedAreas.filter(area => area.id !== id))
  }

  /**
   * Used to toggle between selected and unselected state
   * @param checked boolean to determine if areas should be selected or not
   * @remarks when selecting it will use `filteredAreas` to pick only the areas that are currently visible
   */
  function selectAllChange(checked: boolean, setEmpty?: boolean) {
    if (checked)
      setSelectedAreas([...unref(filteredAreas)])
    else
      setSelectedAreasFromAreas(setEmpty)
  }

  // refresh selected areas when areas change
  watchDeep(areas, () => {
    setSelectedAreasFromAreas()
  })

  return {
    areAllAreaSelected,
    filteredAreas,
    initializeSelectedAreas,
    isAreaSelected,
    isAtLeastOneAreaSelected,
    searchTerm,
    selectAllChange,
    selectedAreaObject,
    selectedAreas,
    selectedAreasArray,
    selectedFilteredAreasArray,
    unselectArea,
    unselectedFilteredAreasArray,
  }
}
